import firebase from 'firebase'

const SET = property => (state, payload) => (state[property] = payload)
const GET = (property, def) => state => state[property] ? state[property] : def
const GET_DB = dbname => firebase.firestore().collection(dbname)

const LIST_SNAPSHOT = querySnapshot => {
  let items = []
  querySnapshot.forEach((elem) => {
    items.push({ id: elem.id, ...elem.data() })
  })
  return items
}

export {
  SET,
  GET,
  GET_DB,
  LIST_SNAPSHOT
}
