import firebase from 'firebase'

class Model {
  constructor (firestore, collectionName, serializer) {
    this.firestore = firestore
    this.db = firestore.collection(collectionName)
    this.name = collectionName
    this.validation = null
    this.serializer = serializer
    // super(collectionName)
    this.partialUpdate = this.partialUpdate.bind(this)
    this.retrieve = this.retrieve.bind(this)
    this.destroy = this.destroy.bind(this)
    this.create = this.create.bind(this)
  }

  addTimestamp (data, name) {
    data[name] = firebase.firestore.FieldValue.serverTimestamp()
    return data
  }

  async validate (data) {
    let s = this.serializer
    try {
      await this.serializer.validate(data)
    } catch (e) {
      this.validation = false
      return {
        message: 'Error en formulario',
        errors: e
      }
    }
    this.validation = s.is_valid
    return s.data
  }

  _fromFirebase (elem) {
    let id = elem.id
    return { id, ...elem.data() }
  }

  async retrieve (id) {
    let instance = await this.db.doc(id).get()
    return this._fromFirebase(instance)
  }

  async destroy (id) {
    await this.db.doc(id).delete()
    return true
  }

  async list () {
    let lista = []
    let response = await this.db.get()
    response.forEach((elem) => {
      lista.push(this._fromFirebase(elem))
    })
    return lista
  }

  async partialUpdate (id, data) {
    return this.update(id, data, true)
  }
  async update (id, data, partial = false) {
    let d = { ...data }
    if (partial) {
      this.serializer._setSchema(Object.keys(data))
    }
    let validData = await this.validate({ ...d })
    if (!this.validation) return Promise.reject(validData)
    validData = this.addTimestamp(validData, 'updated_at')
    return this.db.doc(id).update(validData)
  }

  async create (data) {
    let validData = await this.validate({ ...data })
    if (!this.validation) return Promise.reject(validData)
    validData = this.addTimestamp(validData, 'created_at')
    return Promise.resolve(this.db.add(validData))
  }
}

export default Model
