// https://vuex.vuejs.org/en/state.html

export default {
  store: this,
  auth: {
    user: null
  },
  alert: {
    tit: '',
    msg: '',
    type: 'success'
  },
  socket: {
    isConnected: false,
    message: '',
    reconnectError: false
  }
}
