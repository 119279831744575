import Vue from 'vue'

import './plugins/firebase'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import './plugins'
import './directives'
import './components/global'

import App from './App.vue'

Vue.config.productionTip = false

const app = new Vue({
  router,
  vuetify,
  store,
  render: h => h(App)
})

store.state.$app = app
app.$mount('#app')
