
import users from './users'
// import games from './games'
import AppMutations from './app/mutations'
import AppState from './app/state'

let app = {
  namespaced: true,
  mutations: AppMutations,
  state: AppState
}

// https://vuex.vuejs.org/en/modules.html

// const requireModule = require.context('.', true, /\.js$/)
// const modules = {}

// requireModule.keys().forEach(fileName => {
//   if (fileName === './index.js') return

//   // Replace ./ and .js
//   const path = fileName.replace(/(\.\/|\.js)/g, '')
//   const [moduleName, imported] = path.split('/')
//   console.log(moduleName)
//   console.log(imported)

//   if (!modules[moduleName]) {
//     modules[moduleName] = {
//       namespaced: true
//     }
//   }

//   modules[moduleName][imported] = requireModule(fileName).default
// })

// console.log(modules)
export default {
  users: { namespaced: true, ...users },
  app
}
