
function ListaResultados (albarounds, batiarounds) {
  this.albarounds = albarounds
  this.batiarounds = batiarounds
  this.scenarios = {
    '30-30': [11, 11, false],
    '30-20': [2, 18, false],
    '30-10': [2, 15, false],
    '20-30': [18, 2, false],
    '20-20': [8, 8, false],
    '20-10': [3, 15, false],
    '10-30': [15, 2, false],
    '10-20': [15, 3, false],
    '10-10': [5, 5, false]
  }
  this.total_elements = 8
  this.perfect = [
    {
      // Ronda 1
      A: 30,
      B: 30,
      Utilidad_A: 11,
      Utilidad_B: 11
    },
    {
      // Ronda 2
      A: 30,
      B: 30,
      Utilidad_A: 11,
      Utilidad_B: 11
    },
    {
      // Ronda 3
      A: 30,
      B: 30,
      Utilidad_A: 11,
      Utilidad_B: 11
    },
    {
      // Ronda 4
      A: 30,
      B: 30,
      Utilidad_A: 22,
      Utilidad_B: 22
    },
    {
      // Ronda 5
      A: 30,
      B: 30,
      Utilidad_A: 11,
      Utilidad_B: 11
    },
    {
      // Ronda 6
      A: 30,
      B: 30,
      Utilidad_A: 11,
      Utilidad_B: 11
    },
    {
      // Ronda 7
      A: 30,
      B: 20,
      Utilidad_A: 2,
      Utilidad_B: 72
    },
    {
      // Ronda 8
      A: 20,
      B: 30,
      Utilidad_A: 72,
      Utilidad_B: 2
    }
  ]
}

ListaResultados.prototype.negotiations = function () {
  let negotiations = []
  for (var i = 0; i <= this.total_elements - 1; i++) {
    if (this.albarounds[i] && this.batiarounds[i]) {
      negotiations.push(this.albarounds[i] + '-' + this.batiarounds[i])
    } else {
      negotiations.push(null)
    }
  }
  return negotiations
}

ListaResultados.prototype.activeRound = function () {
  var round = 1
  for (var i = 0; i <= this.total_elements - 1; i++) {
    if (this.albarounds[i] && this.batiarounds[i]) {
      round++
      continue
    } else {
      break
    }
  }
  return round
}

ListaResultados.prototype.neg_array = function () {
  let negotiations = []
  for (var i = 0; i <= this.total_elements - 1; i++) {
    if (this.albarounds[i] && this.batiarounds[i]) {
      negotiations.push([this.albarounds[i], this.batiarounds[i], false])
    } else {
      negotiations.push(null)
    }
  }
  return negotiations
}
ListaResultados.prototype.neg_toshow = function () {
  let negotiations = []
  for (var i = 0; i <= this.total_elements - 1; i++) {
    if (this.albarounds[i] && this.batiarounds[i]) {
      negotiations.push([this.albarounds[i], this.batiarounds[i], false])
    } else {
      negotiations.push([0, 0, false])
    }
  }
  return negotiations
}
ListaResultados.prototype.neg_edit = function () {
  let negotiations = []
  for (var i = 0; i <= this.total_elements - 1; i++) {
    let a = this.albarounds[i] ? this.albarounds[i] : 0
    let b = this.batiarounds[i] ? this.batiarounds[i] : 0
    negotiations.push([a, b, false])
  }
  return negotiations
}

ListaResultados.prototype.utilidades = function () {
  const self = this
  var next = true
  let ur = this.negotiations().map(function (elem, index, array) {
    function multiplicar (u, cociente) {
      if (cociente === 4) {
        let new_el = []
        new_el[0] = u[0] > u[1] ? u[0] * 4 : u[0]
        new_el[1] = u[1] > u[0] ? u[1] * 4 : u[1]
        new_el[2] = false
        return new_el
      } else {
        let new_el = u.map(elem => elem * cociente)
        new_el[2] = false
        return new_el
      }
    }

    if (elem) {
      let utilidad = self.scenarios[elem]

      if (index == 3) {
        return multiplicar(utilidad, 2)
      } else if (index == 6 || index == 7) {
        if (utilidad[0] == utilidad[1]) {
          return utilidad
        } else {
          return multiplicar(utilidad, 4)
        }
      } else {
        return utilidad
      }
    } else {
      if (next) {
        next = false
        return [0, 0, false]
      } else {
        return [0, 0, true]
      }
    }
  })

  return ur
}
ListaResultados.prototype.acumulado = function () {
  var Aprev = 0
  var Bprev = 0
  let acum = []
  let ur = this.utilidades().map(function (elem, index, array) {
    if (elem) {
      Aprev = elem[0] + Aprev
      Bprev = elem[1] + Bprev

      let ronda = [Aprev, Bprev]

      acum.push(ronda)
    } else {
      acum.push([0, 0])
    }
  })

  return acum
}

ListaResultados.prototype.fullResults = function () {
  return {
    utilidades: this.utilidades(),
    acumulado: this.acumulado(),
    negociacion: this.neg_toshow(),
    edition: this.neg_edit(),
    ronda: this.activeRound()
  }
}

export default ListaResultados
