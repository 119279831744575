import Vue from 'vue'
import Router from 'vue-router'

import paths from '@/router/paths'
import { beforeEach, groupAccessFilter, mainPageFilter } from '@/router/guards'
import basePaths from '@/router/basePaths'
import firebase from '@/plugins/firebase'

Vue.use(Router)

let NavPaths
// NavPaths = Vue.$django ? Vue.$django.navigation_paths : ''

function route (path) {
  return {
    name: path.name,
    path: path.path,
    component: path.component,
    meta: { ...path }
  }
}

let routes = basePaths.map(function (parent) {
  let children = paths.filter(x => x.parent === parent.name)
  // children = children.filter(groupAccessFilter)
  // children = children.map(mainPageFilter)
  return {
    ...parent,
    component: parent.component,
    children: children.map(x => route(x))
  }
})

// let FinalRoutes = NavPaths ? routes.concat(NavPaths) : routes

const ROUTER = new Router({
  mode: 'history',
  routes: routes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    }
    if (to.hash) {
      return { selector: to.hash }
    }
    return { x: 0, y: 0 }
  }
})

firebase.getCurrentUser = () => {
  return new Promise((resolve, reject) => {
    const unsubscribe = firebase.auth().onAuthStateChanged(user => {
      unsubscribe()
      resolve(user)
    }, reject)
  })
}
ROUTER.beforeEach(async (to, from, next) => {
  ROUTER.app.loading = true
  const requiresAuth = to.matched.some(route => route.meta.requiresAuth)
  if (requiresAuth && !await firebase.getCurrentUser()) {
    next({ name: 'login' })
  } else {
    next()
  }
  ROUTER.app.loading = false
})

export default ROUTER
