export default [
  {
    parent: 'base',
    path: '/login',
    name: 'login',
    component: () => import('@/views/Login.vue')
  },
  {
    parent: 'dashboard',
    path: '/',
    name: 'main',
    requiresAuth: true,
    component: () => import('@/views/Home.vue')
  },
  {
    parent: 'dashboard',
    path: 'users',
    name: 'users',
    requiresAuth: true,
    component: () => import('@/views/Users.vue')
  },
  {
    parent: 'dashboard',
    path: 'results/:id',
    name: 'results',
    requiresAuth: true,
    component: () => import('@/views/Results.vue')
  },
  {
    parent: 'dashboard',
    path: 'team/:id',
    name: 'team',
    component: () => import('@/views/Team.vue')
  }
]
