// https://vuex.vuejs.org/en/mutations.html

export default {
  setUser: (state, user) => {
    state.user = user
  },
  showAlert (state, alertData) {
    state.alert = alertData
  },
  hideAlert (state) {
    state.alert = {
      tit: '',
      msg: '',
      type: 'hide'
    }
  },
  SOCKET_ONOPEN (state, event) {
    // Vue.prototype.$socket = event.currentTarget
    // state.socket.isConnected = true
    console.log('Conected')
  },
  SOCKET_ONCLOSE (state, event) {
    // state.socket.isConnected = false
    state.alert = {
      type: 'reconecting',
      tit: 'Conexion interrumpida',
      msg: 'se ha interrumpido la conexion, reintentando'
    }
  },
  SOCKET_ONERROR (state, event) {
    state.alert = {
      type: 'danger',
      tit: 'Error',
      msg: 'No hay conexion'
    }
  },
  // default handler called for all methods
  SOCKET_ONMESSAGE (state, message) {
    state.socket.message = message
  },
  // mutations for reconnect methods
  SOCKET_RECONNECT (state, count) {
    state.alert = {
      type: 'success',
      tit: 'Conexion Recuperada'
    }
  },
  SOCKET_RECONNECT_ERROR (state) {
    state.alert = {
      type: 'danger',
      tit: 'Error',
      msg: 'No hay conexion'
    }
    state.socket.reconnectError = true
  }

}
