export default {
  letras: [
    { 'value': 0, 'text': 'A' },
    { 'value': 1, 'text': 'B' },
    { 'value': 2, 'text': 'C' },
    { 'value': 3, 'text': 'D' },
    { 'value': 4, 'text': 'E' },
    { 'value': 5, 'text': 'F' },
    { 'value': 6, 'text': 'G' },
    { 'value': 7, 'text': 'H' },
    { 'value': 8, 'text': 'I' },
    { 'value': 9, 'text': 'J' },
    { 'value': 10, 'text': 'K' },
    { 'value': 11, 'text': 'L' },
    { 'value': 12, 'text': 'M' },
    { 'value': 13, 'text': 'N' },
    { 'value': 14, 'text': 'O' },
    { 'value': 15, 'text': 'P' },
    { 'value': 16, 'text': 'Q' },
    { 'value': 17, 'text': 'R' },
    { 'value': 18, 'text': 'S' },
    { 'value': 19, 'text': 'T' },
    { 'value': 20, 'text': 'U' },
    { 'value': 21, 'text': 'V' },
    { 'value': 22, 'text': 'W' },
    { 'value': 23, 'text': 'X' },
    { 'value': 24, 'text': 'Y' },
    { 'value': 25, 'text': 'Z' }
  ]
}
