import * as yup from 'yup'

function processErrors (error_data) {
  let errors = {}
  error_data.forEach(function (ValError) {
    errors[ValError.path] = ValError.message
  })
  return errors
}

class Serializer {
  constructor (schema, callbacks = {}, mantainExtra = false) {
    this.initialSchema = schema
    this._setSchema()

    this.is_valid = null
    this.data = null
    this.errors = null
    this.postValCallbacks = []
    this.preValCallbacks = []

    if (callbacks.pre) {
      if (typeof callbacks.pre !== 'function') throw new Error('preValidation must be a function')
      this.preValCallbacks.push(callbacks.pre)
    }

    if (callbacks.post) {
      if (typeof callbacks.post !== 'function') throw new Error('preValidation must be a function')
      this.postValCallbacks.push(callbacks.post)
    }

    this._removeExtra = this._removeExtra.bind(this)
    if (!mantainExtra) this.postValCallbacks.push(this._removeExtra)
  }

  _postValidation (data) {
    let valdata = data
    this.postValCallbacks.forEach(callback => {
      valdata = callback(valdata)
    })
    return valdata
  }

  _preValidation (data) {
    let valdata = data
    this.preValCallbacks.forEach(callback => {
      valdata = callback(valdata)
    })
    return valdata
  }

  _removeExtra (data) {
    let datakeys = Object.keys(data)
    let valdata = data
    datakeys.forEach(attr => {
      if (!this.schemaKeys.includes(attr)) {
        delete valdata[attr]
      }
    })
    return valdata
  }

  _setSchema (only = []) {
    let schema = {}
    if (only.length) {
      only.forEach(attr => {
        schema[attr] = this.initialSchema[attr]
      })
    } else {
      schema = { ...this.initialSchema }
    }

    this.schemaKeys = Object.keys(schema)
    this.schema = yup.object().shape(schema)
    return true
  }

  validate (data) {
    const self = this

    let valData = { ...data }
    try {
      valData = self._preValidation(valData)
    } catch (error) {
      self.is_valid = false
      self.error_data = error
      return Promise.reject(error)
    }
    return this.schema.validate(valData, { abortEarly: false }).then(succes => {
      self.is_valid = true
      self.data = self._postValidation(succes)
    }).catch(err => {
      self.is_valid = false
      self.error_data = err
      self.data = {
        message: 'Error en formulario',
        errors: processErrors(err.inner)
      }
    })
  }
}

export default Serializer
