
import { GET, SET, GET_DB } from '../utils'

export default {
  state: {
    usersList: null
  },

  getters: {
    list: GET('usersList', [])
  },

  mutations: {
    LIST_USERS: SET('usersList')
  },
  actions: {
    FETCH_USERS: async ({ commit, rootState }) => {
      const APP = rootState.$app
      let users = await APP.$firestore.models.users.list()
      commit('LIST_USERS', users)
    }
  }
}
