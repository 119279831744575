import Vue from 'vue'
import firebase from '@/plugins/firebase'
// const DJANGO = Vue.$django ? Vue.$django : DJANGO_CONTEXT ? DJANGO_CONTEXT : null
// const USER_AUTH = DJANGO ? DJANGO.user : DJANGO
// const USER_GROUPS = USER_AUTH ? USER_AUTH.groups.map(x=>x.name) : []
const USER_GROUPS = [ 'admin' ]
const USER_AUTH = firebase.auth().currentUser

const beforeEach = function (to, from, next) {
  console.log(USER_AUTH)
  console.log(Vue.$firebase)
  console.log(this)
  // let goingToLogin = to.name === 'login'
  // if (!USER_AUTH) {
  //   if (!goingToLogin) next({ name: 'login' })
  //   else next()
  // } else if (to.name === 'login') {
  //   next({ name: 'main' })
  // } else {
  //   next()
  // }
}

const beforeResolve = function (to, from, next) {
  return to
}

const afterEach = function (to, from, next) {
  return to
}

const groupAccessFilter = function (path) {
  if (!path.access) return true
  if (USER_GROUPS.includes('admin')) return true
  for (var i = 0; i < USER_GROUPS.length; i++) {
    if (path.access.groups.includes(USER_GROUPS[i])) return true
  }
  return false
}
const mainPageFilter = function (path) {
  if (path.name === 'main') {
    path.component = () => import('@/views/Home.vue')
  }
  return path
}

export {
  beforeEach,
  beforeResolve,
  afterEach,
  groupAccessFilter,
  mainPageFilter
}
