import * as yup from 'yup'

import { setLocale } from 'yup'

import BaseSerializer from './base/serializer'
import BaseModel from './base/model'

import moment from 'moment'
import firebase from 'firebase'
import alfabeto from '@/assets/afabeto'
import Results from '@/assets/results'

const DB = firebase.firestore()
// if (location.hostname === 'localhost') {
//   // DB.useEmulator('localhost', 8080)
//   DB.useEmulator('localhost', 8000)
//   DB.settings({
//     host: 'localhost:8000',
//     ssl: false
//   })
// } else {
// }
DB.settings({ timestampsInSnapshots: true })

window.DB = DB
setLocale({
  mixed: {
    default: 'Datos invalidos'
  },
  // required: 'No puede estar vacio',
  // string: 'Debe ser texto',
  // email: {
  //   default: 'Debe tener formato de email'
  // },
  string: {
    email: 'Introduzca una dirección de email valida',
    required: 'Campo no puede estar vacio',
    min: 'Debe tener mínimo ${min} caracteres',
    max: 'Debe tener máximo ${max} caracteres'
  },
  number: {
    default: 'Debe ser un numero',
    min: 'Debe ser mayor a ${min}',
    max: 'Debe ser menor que ${max}'
  }
})

let userSchema = {
  first_name: yup.string().required(),
  last_name: yup.string().required(),
  username: yup.string().required().min(6),
  email: yup.string().email(),
  password: yup.string().required().min(6),
  passwordconf: yup.string().required().min(6),
  is_staff: yup.boolean(),
  uid: yup.string()
}

let userCallbacks = {
  pre: data => {
    if (data.passwordconf !== data.password) {
      const errorReponse = {
        'password': 'Las contraseñas deben ser iguales',
        'passwordconf': 'Las contraseñas deben ser iguales'
      }
      throw errorReponse
    }
    return data
  },
  post: data => {
    delete data.passwordconf
    return data
  }
}

class User extends BaseModel {
  constructor () {
    const userSerializer = new BaseSerializer(userSchema, userCallbacks)
    super(DB, 'users', userSerializer)
    // this.__resultsOfGroup = this.__resultsOfGroup.bind(this)
  }
  async create (data) {
    let validData = await this.validate({ ...data })
    if (!this.validation) return Promise.reject(validData)
    let { email, password } = validData
    let user = await firebase.auth().createUserWithEmailAndPassword(email, password)
      .then(resp => {
        validData.uid = resp.user.uid
        console.log(validData)
        return validData
      }).catch(error => {
        var errorCode = error.code
        var errorMessage = error.message
        console.log(errorCode)

        return errorMessage
      })
    if (typeof user === 'string') {
      let message = { message: user }
      return Promise.reject(message)
    }
    validData = this.addTimestamp(user, 'created_at')
    return Promise.resolve(this.db.add(validData))
  }
}

const users = new User()

let gameSchema = {
  name: yup.string().required(),
  place: yup.string().required(),
  date: yup.date().required(),
  groups: yup.number().max(25).required(),
  initial: yup.string().required()
}

let gameCallbacks = {
  pre: data => {
    data.date = moment(data.date, 'DD-MM-YYYY')
    return data
  }
}

const gameSerializer = new BaseSerializer(gameSchema, gameCallbacks)

let teamSchema = {
  game: yup.string().required(),
  group: yup.string().required(),
  name: yup.string().required(),
  r1: yup.number().max(30).required().default(() => 0),
  r2: yup.number().max(30).required().default(() => 0),
  r3: yup.number().max(30).required().default(() => 0),
  r4: yup.number().max(30).required().default(() => 0),
  r5: yup.number().max(30).required().default(() => 0),
  r6: yup.number().max(30).required().default(() => 0),
  r7: yup.number().max(30).required().default(() => 0),
  r8: yup.number().max(30).required().default(() => 0)
}

class Team extends BaseModel {
  constructor () {
    const teamSerializer = new BaseSerializer(teamSchema)
    super(DB, 'teams', teamSerializer)
    this.__resultsOfGroup = this.__resultsOfGroup.bind(this)
  }

  __resultsByTeam (team, results) {
    let index = team.name === 'A' ? 0 : 1
    team.results = team.results.map((res, idx) => {
      return {
        ...res,
        utilidades: results.utilidades[idx][index],
        acumulado: results.acumulado[idx][index],
        negociacion: results.negociacion[idx][index],
        edition: results.edition[idx][index]
      }
    })
    team.reference = this.db.doc(team.id)
    return team
  }

  __resultsOfGroup (group) {
    let res = new Results(
      group.alba.results.map(r => r.value),
      group.batia.results.map(r => r.value)
    ).fullResults()

    let covers = group.alba.results.map((v, i) => {
      return [
        v.cover,
        group.batia.results[i].cover
      ]
    })
    group.alba = this.__resultsByTeam(group.alba, res)
    group.batia = this.__resultsByTeam(group.batia, res)
    return {
      ...group,
      ...res,
      covers
    }
  }

  async getGroup (team) {
    let response = await this.db
      .where('game', '==', team.game)
      .where('group', '==', team.group)
      .get().then(response => {
        let lista = []
        response.forEach((elem) => {
          lista.push(this._fromFirebase(elem))
        })
        return lista
      })
    console.log(response)
    return response
  }

  async toggleCovers (team, round) {
    let response
    var batch = DB.batch()
    if (round === 3) {
      response = await this.getGroup(team).then(teams => {
        teams.forEach(team => {
          let results = team.results
          results[3].cover = !results[3].cover
          batch.update(this.db.doc(team.id), { results })
        })
        return batch.commit()
      })
    } else if ([6, 7].includes(round)) {
      response = await this.getGroup(team).then(teams => {
        teams.forEach(team => {
          let results = team.results
          results[6].cover = !results[6].cover
          results[7].cover = !results[7].cover
          batch.update(this.db.doc(team.id), { results })
        })
        return batch.commit()
      })
    } else {
      response = await super.retrieve(team.id).then(team => {
        console.log('patialupdate')
        let results = team.results
        results[round].cover = !results[round].cover
        return super.partialUpdate(team.id, { results })
      })
    }
    return response
  }

  async setValue (id, round, value) {
    let response = await super.retrieve(id).then(team => {
      let results = team.results
      results[round].value = value
      return super.partialUpdate(id, { results })
    })
    return response
  }

  groupAsObject (groupArray) {
    if (groupArray[0].name === 'A') {
      return {
        alba: groupArray[0],
        batia: groupArray[1]
      }
    }
    return {
      alba: groupArray[1],
      batia: groupArray[0]
    }
  }

  async teamResults (id) {
    const self = this
    let team = await super.retrieve(id)
    let group = await self.getGroup(team).then(game => {
      let grup = this.groupAsObject(game)
      let res = this.__resultsOfGroup(grup)
      if (team.name === 'A') {
        res.rival = res.batia
        res.me = res.alba
      } else {
        res.rival = res.alba
        res.me = res.batia
      }
      return res
    })
    return group
  }
}

const teams = new Team()

class Game extends BaseModel {
  constructor () {
    super(DB, 'games', gameSerializer)
  }

  async groups (gameID) {
    let groupList = await teams.db
      .where('game', '==', this.db.doc(gameID))
      .orderBy('name')
      .get().then(snapshot => {
        let groups = {}
        snapshot.forEach(teamref => {
          let team = this._fromFirebase(teamref)
          team['round'] = 1
          team.results.forEach((res) => {
            if (res.value !== 0) team.round = team.round + 1
          })

          if (!groups[team.group]) groups[team.group] = { name: team.group }
          if (team.name === 'A') groups[team.group]['alba'] = team
          else groups[team.group]['batia'] = team
        })
        return Object.values(groups)
      })

    groupList.sort(function (a, b) {
      return (a.name - b.name)
    })
    return groupList
  }

  async create (data) {
    super.create(data).then(
      gameRef => {
        // let game = this.doc(gameRef.id)
        let group = data.initial
        let total = data.groups
        let letraIndex = alfabeto.letras.find(letra => letra.text === group).value
        let baseResults = [
          { cover: true, value: 0 },
          { cover: true, value: 0 },
          { cover: true, value: 0 },
          { cover: true, value: 0 },
          { cover: true, value: 0 },
          { cover: true, value: 0 },
          { cover: true, value: 0 },
          { cover: true, value: 0 }
        ]

        var batch = DB.batch()
        while (total > 0) {
          batch.set(teams.db.doc(), {
            group,
            game: gameRef,
            objetive: '',
            estrategia: '',
            results: baseResults,
            name: 'A' })
          batch.set(teams.db.doc(), {
            group,
            objetive: '',
            estrategia: '',
            game: gameRef,
            results: baseResults,
            name: 'B' })
          total = total - 1
          letraIndex = letraIndex + 1
          if (letraIndex > 26) letraIndex = 0
          group = alfabeto.letras[letraIndex].text
        }
        batch.commit()
        return gameRef
      }, error => {
        console.error(error)
        return error
      })
  }
}

export default {
  models: { users, games: new Game(), teams },
  db: DB
}
