import Vue from 'vue'
import Vuetify from 'vuetify/lib'// Icons
import '@fortawesome/fontawesome-free/css/all.css'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    options: {
      customProperties: true
    },
    themes: {
      light: {
        secondary: '#363636',
        primary: '#DFDFE0',
        terciary: '#FC8F00',
        batia: '#ff7003',
        alba: '#539600',
        capita: 'rgba(43,159,255,1)',
        error: '#F71212',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FFC107'
      }
    }
  },
  icons: { iconfont: 'fa'
  }
})
