import Vue from 'vue'
// import storageService from '../services/storageService'
class Translator {
  constructor (lang = 'ES') {
    this.WORDS = {
      delete_warning: {
        ES: '¿Esta seguro de que desea eliminar todas las dinámicas?, los datos no podran ser recuperados',
        EN: 'Are you sure do you want to delete all the registered games? After this the data cannot be recovered'
      },
      copied: {
        ES: 'Copiado en el portapapeles',
        EN: 'Copied to clipboard'
      },
      yes_no: {
        ES: ['Confirmar', 'Cancelar'],
        EN: ['Confirm', 'Cancel']
      },
      useraccess: {
        ES: 'Accesos',
        EN: 'User Acces'
      },
      username: {
        ES: 'Nombre de Usuario',
        EN: 'Username'
      },
      password: {
        ES: 'Contraseña',
        EN: 'password'
      },
      todefine: {
        ES: 'Por definir',
        EN: 'To define'
      },
      edit: {
        ES: 'Editar',
        EN: 'Edit'
      },
      exit: {
        ES: '¿Desea salir de la aplicación?',
        EN: 'Leave the app?'
      },
      cambiar_idioma: {
        ES: 'Cambiar el idioma a Ingles',
        EN: 'Change language to Spanish'
      },
      borrar_datos: {
        ES: 'Borrar Datos',
        EN: 'Empty Data'
      },
      salir: {
        ES: 'Salir',
        EN: 'Exit'
      },
      initial_l: {
        ES: 'Iniciar en:',
        EN: 'Begin at:'
      },
      dinamicas_realizadas: {
        ES: 'Dinámicas realizadas hasta la fecha',
        EN: 'Games played to date'
      },
      utilidad: {
        EN: 'Utility',
        ES: 'Utilidad'
      },
      acumulado: {
        EN: 'Acumulated',
        ES: 'Acumulado'
      },
      negociacion: {
        EN: 'Negotation',
        ES: 'Negociacion'
      },
      nueva: {
        EN: 'New',
        ES: 'Nueva'
      },
      nueva_dinamica: {
        EN: 'New Game',
        ES: 'Nueva Dinámica'
      },
      dinamicas_en_total: {
        EN: 'games in total',
        ES: 'dinámicas en total'
      },
      no_games: {
        EN: 'There is no games created',
        ES: 'Aun no existen dinámicas creadas'
      },
      crea_la_primera: {
        ES: 'Crea la primera!',
        EN: 'Create the first!'
      },
      groups: {
        EN: 'Groups',
        ES: 'Grupos'
      },
      group_sin: {
        EN: 'Universe',
        ES: 'Universo'
      },
      ronda: {
        EN: 'Round',
        ES: 'Ronda'
      },
      activa: {
        EN: 'Active',
        ES: 'Activa'
      },
      finished: {
        EN: 'Finished',
        ES: 'Terminada'
      },
      roundneg: {
        EN: 'Round Negotiation',
        ES: 'Negociacion de Ronda'
      },
      nombre: {
        EN: 'Name',
        ES: 'Nombre'
      },
      place: {
        EN: 'Place',
        ES: 'Lugar'
      },
      team_objective: {
        ES: 'Objetivo',
        EN: 'Objective'
      },
      teamstrategy: {
        EN: 'Strategies',
        ES: 'Estrategia'
      },
      fecha: {
        EN: 'Date',
        ES: 'Fecha'
      },
      create: {
        EN: 'Create',
        ES: 'Crear'
      },
      add_group_question: {
        EN: 'Do you wish to add  a new Group?',
        ES: '¿Desea agregar un nuevo grupo?'
      },
      add_group: {
        EN: 'Add Group',
        ES: 'Agregar grupo'
      },
      finish_game: {
        EN: 'Finish Game',
        ES: 'Terminar Juego'
      },
      finish_game_warning: {
        EN: 'Doing this you close the chanse to add new groups, and make changes to the game',
        ES: 'Al hacerlo se eliminara la posibilidad de ingresar a hacer cambios en todos los grupos'
      },
      results: {
        EN: 'Results',
        ES: 'Resultados'
      },
      reveal_all: {
        EN: 'Reveal All',
        ES: 'Mostrar Todos'
      },
      hide_all: {
        EN: 'Hide All',
        ES: 'Ocultar todos'
      },
      send_form: {
        EN: 'Send',
        ES: 'Registrar'
      },
      of_event: {
        EN: 'of event',
        ES: ' del evento'
      },
      create_new: {
        EN: 'Create new game',
        ES: 'Crea nueva dinámica'
      },
      confirmation: {
        EN: 'contirmation',
        ES: 'Confirmación'
      },
      terminada: {
        EN: 'Finished',
        ES: 'Terminada'
      }
    }
    this.lang = lang
  }

  toggleLang () {
    if (this.lang === 'ES') this.lang = 'EN'
    else this.lang = 'ES'
  }
  get (idx) {
    if (this.WORDS[idx]) return this.WORDS[idx][this.lang]
    console.error('no encontrado')
    console.error(idx)
    return ''
  }
}

const LANG = 'ES'

Vue.prototype.$words = new Translator(LANG)
