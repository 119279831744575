<template>
  <div id="app">
    <div v-if="$root.loading" class="justify-content-center">
      <LoadingComponent style="margin:auto"/>
    </div>
   <router-view/>
  </div>
</template>

<script>
import firebase from 'firebase'
export default {
  mounted () {
    window.onoffline = this.offline
    window.ononline = this.recovered
  }
}

</script>

<style lang="scss">

html{
    background:#fff;
    margin:0;
    padding:0;
    width:100%;
    height:100%;
    font-size: 11px;
    /*-webkit-font-smoothing: subpixel-antialiased !important;*/
    scroll-behavior: smooth;
}

body{
    width:100%;
    height:100%;
    padding:0;
    position:relative;
    font-weight:300;
    font-size: 13px;
    font-family: 'Roboto Condensed', sans-serif;
}
.angosta {
  max-width:300px;
}

.redcolor{
    color:#FD1A27;
}
.grencolor{
    color:#03C526;

}
.bluecolor{
    color:#1B58D4;
}

.specialRound{
    font-weight: 900;
}
</style>
