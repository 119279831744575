import firebase from 'firebase/app'

// const admin = require('firebase-admin')

const firebaseConfig = {
  apiKey: 'AIzaSyBFaGiCcFw41FJZOLzZkKr-YtDsHsMG6fg',
  authDomain: 'cic-cotizacion.firebaseapp.com',
  databaseURL: 'https://cic-cotizacion.firebaseio.com',
  projectId: 'cic-cotizacion',
  storageBucket: 'cic-cotizacion.appspot.com',
  messagingSenderId: '239313601654',
  appId: '1:239313601654:web:0a7c349ab0afbe1daa0b57',
  measurementId: 'G-HEHMZJCWX0'
}

// if (location.hostname === 'localhost') {
//   firebase.initializeApp({
//     appId: '1:239313601654:web:0a7c349ab0afbe1daa0b57',
//     apiKey: 'AIzaSyBFaGiCcFw41FJZOLzZkKr-YtDsHsMG6fg',
//     projectId: 'cic-cotizacion',
//     databaseURL: 'http://localhost:8000'
//   })
// } else {
// }
firebase.initializeApp(firebaseConfig)

export default firebase
